import { Spinner } from '@HarrisonKeeling/design-system';
import PropTypes from 'prop-types';
import React, { Suspense, lazy, useEffect } from 'react';
import {
    BrowserRouter as Router,
    Redirect,
    Route,
    Switch,
} from 'react-router-dom';
import { useAuth } from 'contexts/auth';
import { useEnvironment } from 'contexts/environment';
import Navigation from 'components/Navigation';
import './app.scss';

const LoadableDashboard = lazy(() => import('features/Dashboard'));
const LoadableDispute = lazy(() => import('features/Dispute'));
const LoadableLoggedOutHomepage = lazy(() =>
    import('features/LoggedOutHomepage')
);
const LoadableInspection = lazy(() => import('features/Inspection'));
const LoadableLogin = lazy(() => import('features/Login'));
const LoadableMeeting = lazy(() => import('features/Meeting'));
const LoadableOrder = lazy(() => import('features/OrderView'));
const LoadablePasswordReset = lazy(() => import('features/PasswordReset'));
const LoadablePrivacyPolicy = lazy(() => import('features/PrivacyPolicy'));
const LoadableProject = lazy(() => import('features/ProjectView'));
const LoadableRFB = lazy(() => import('features/RFB'));
const LoadableReport = lazy(() => import('features/Report'));
const LoadableSettings = lazy(() => import('features/Settings'));
const LoadableSignUp = lazy(() => import('features/SignUp'));
const LoadableTermsOfUse = lazy(() => import('features/TermsOfUse'));

const AuthenticatedRoute = ({ fallbackComponent, roles, ...routeProps }) => {
    const {
        state: { loggedIn, user },
    } = useAuth();
    const {
        computedMatch: { params },
    } = routeProps;

    if (!loggedIn) {
        if (Object.values(params).filter(Boolean).length || !fallbackComponent)
            return <Redirect to="/login" />;

        return <Route {...routeProps} component={fallbackComponent} />;
    }

    const { role } = user;
    if (Array.isArray(roles) ? !roles.includes(role) : roles !== role)
        return <Redirect to="/" />;

    return <Route {...routeProps} />;
};

AuthenticatedRoute.propTypes = {
    component: PropTypes.elementType.isRequired,
    fallbackComponent: PropTypes.elementType,
    roles: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.oneOf(['employee', 'partner'])),
    ]),
};

AuthenticatedRoute.defaultProps = {
    fallbackComponent: null,
    roles: ['employee', 'partner'],
};

const App = () => {
    const {
        state: { loggedIn, user },
        refreshUser,
    } = useAuth();
    const {
        fetchConfig,
        state: { loaded: envLoaded },
    } = useEnvironment();

    useEffect(() => {
        fetchConfig();
    }, []);

    useEffect(() => {
        if (loggedIn) {
            refreshUser();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loggedIn]);

    // still fetching user info / validating session / env loading
    if ((loggedIn && !user) || !envLoaded) return <Spinner />;

    return (
        <Router>
            <div className="app">
                <Suspense fallback={<Spinner />}>
                    <Navigation />
                    <Switch>
                        <AuthenticatedRoute
                            exact
                            path="/projects/:id"
                            component={LoadableProject}
                        />
                        <AuthenticatedRoute
                            exact
                            path="/orders/:id/dispute"
                            component={LoadableDispute}
                        />
                        <AuthenticatedRoute
                            exact
                            path="/orders/:id/report"
                            component={LoadableReport}
                        />
                        <AuthenticatedRoute
                            exact
                            path="/orders/:id/:action?"
                            component={LoadableOrder}
                        />
                        <AuthenticatedRoute
                            path="/orders/:order/meet/:meeting"
                            component={LoadableMeeting}
                        />
                        <AuthenticatedRoute
                            path="/rfbs/:id"
                            component={LoadableRFB}
                        />
                        <AuthenticatedRoute
                            path="/settings"
                            component={LoadableSettings}
                        />
                        <Route
                            exact
                            path="/meet/:meeting"
                            component={LoadableInspection}
                        />
                        <Route
                            exact
                            path="/reset-password/:token?"
                            component={LoadablePasswordReset}
                        />
                        <Route exact path="/login" component={LoadableLogin} />
                        <Route
                            exact
                            path="/sign-up/:invite"
                            component={LoadableSignUp}
                        />
                        <Route
                            exact
                            path="/privacy"
                            component={LoadablePrivacyPolicy}
                        />
                        <Route
                            exact
                            path="/terms"
                            component={LoadableTermsOfUse}
                        />
                        <AuthenticatedRoute
                            exact
                            path="/:path?"
                            fallbackComponent={LoadableLoggedOutHomepage}
                            component={LoadableDashboard}
                        />
                    </Switch>
                </Suspense>
            </div>
        </Router>
    );
};

export default App;
