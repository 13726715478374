import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_BASE;

export const addPaymentMethod = async ({ partnerId }) =>
    axios
        .post(
            `${BASE_URL}/payment-methods`,
            { partnerId },
            {
                withCredentials: true,
            }
        )
        .then(({ data }) => data);

export const addTeamMember = async ({ email, partner, role }) =>
    axios
        .post(
            `${BASE_URL}/partners/${partner}/members`,
            { email, role },
            { withCredentials: true }
        )
        .then(({ data }) => data);

export const fetchInviteDetails = async (inviteId) =>
    axios
        .get(`${BASE_URL}/auth/invite/${inviteId}`, {
            withCredentials: true,
        })
        .then(({ data }) => data);

export const fetchJobArchive = async ({ partners }) =>
    axios
        .post(
            `${BASE_URL}/data-export/jobs`,
            { partners },
            {
                responseType: 'blob',
                withCredentials: true,
            }
        )
        .then(({ headers: { 'content-disposition': disposition }, data }) => ({
            buffer: data,
            name: disposition.match(/filename="(.+)?"/)[1],
        }));

export const fetchOutsourcingArchive = async ({ partners }) =>
    axios
        .post(
            `${BASE_URL}/data-export/outsourced`,
            { partners },
            {
                responseType: 'blob',
                withCredentials: true,
            }
        )
        .then(({ headers: { 'content-disposition': disposition }, data }) => ({
            buffer: data,
            name: disposition.match(/filename="(.+)?"/)[1],
        }));

export const fetchPartner = async ({ partnerId, signal }) =>
    axios
        .get(`${BASE_URL}/partners/${partnerId}`, {
            withCredentials: true,
            signal,
        })
        .then(({ data }) => data);

export const fetchPartners = async () =>
    axios
        .get(`${BASE_URL}/partners`, { withCredentials: true })
        .then(({ data }) => data);

export const fetchPartnerProducts = async ({ partnerId, signal }) =>
    axios
        .get(`${BASE_URL}/partners/${partnerId}/products`, {
            signal,
            withCredentials: true,
        })
        .then(({ data }) => data);

export const fetchPartnerStripeStatus = async ({ partnerId, signal }) =>
    axios
        .get(`${BASE_URL}/partners/${partnerId}/stripe/status`, {
            signal,
            withCredentials: true,
        })
        .then(({ data }) => data);

/**
 * Fetches a signed URL for the `partnerId`'s Stripe Dashboard
 * @param partnerId
 * @param type one of `setup` or `login`
 * @returns {Promise<AxiosResponse<never>>}
 */
export const fetchPartnerStripeURL = async ({ partnerId, signal, type }) =>
    axios
        .get(`${BASE_URL}/partners/${partnerId}/stripe/${type}`, {
            signal,
            withCredentials: true,
        })
        .then(({ data }) => data);

export const fetchPaymentMethods = async () =>
    axios
        .get(`${BASE_URL}/payment-methods`, {
            withCredentials: true,
        })
        .then(({ data }) => data);

export const fetchPerformanceReport = async ({ partners, stat, timeframe }) =>
    axios
        .post(
            `${BASE_URL}/data-export/performance-report/${stat}`,
            { partners, timeframe },
            {
                responseType: 'blob',
                withCredentials: true,
            }
        )
        .then(({ headers: { 'content-disposition': disposition }, data }) => ({
            buffer: data,
            name: disposition.match(/filename="(.+)?"/)[1],
        }));

export const fetchSchedulingTemplate = async ({ partnerId, productId }) =>
    axios
        .get(
            `${BASE_URL}/partners/${partnerId}/products/${productId}/scheduling-template`,
            {
                withCredentials: true,
            }
        )
        .then(({ data }) => data);

export const fetchSchedulingTemplateAvailability = async ({
    interval,
    partnerId,
    productId,
    start,
    end,
    signal,
}) =>
    axios
        .get(
            `${BASE_URL}/partners/${partnerId}/products/${productId}/scheduling-availability`,
            {
                params: { interval, start, end },
                signal,
                withCredentials: true,
            }
        )
        .then(({ data }) => data);

export const fetchTeam = async (partnerId) =>
    axios
        .get(`${BASE_URL}/partners/${partnerId}/members`, {
            withCredentials: true,
        })
        .then(({ data }) => data);

export const fetchTeamMember = async ({ partner, member }) =>
    axios
        .get(`${BASE_URL}/partners/${partner}/members/${member}`, {
            withCredentials: true,
        })
        .then(({ data }) => data);

export const fetchUser = async () =>
    axios
        .get(`${BASE_URL}/users`, { withCredentials: true })
        .then(({ data }) => data);

export const joinWaitlist = async ({ name, email, organization, interests }) =>
    axios
        .post(`${BASE_URL}/waitlist`, { name, email, organization, interests })
        .then(({ data }) => data);

export const login = async ({ email, password }) =>
    axios
        .post(
            `${BASE_URL}/auth`,
            { email, password },
            { withCredentials: true }
        )
        .then(({ data }) => data);

export const logout = async () =>
    axios.post(`${BASE_URL}/auth/logout`, null, { withCredentials: true });

export const removePaymentMethod = async (id) =>
    axios.delete(`${BASE_URL}/payment-methods/${id}`, {
        withCredentials: true,
    });

export const removeTeamMember = async ({ partner, id }) =>
    axios.delete(`${BASE_URL}/partners/${partner}/members/${id}`, {
        withCredentials: true,
    });

export const requestReset = async ({ email, password, token }) =>
    axios
        .post(
            `${BASE_URL}/auth/reset-password`,
            { email, password },
            { params: { token } }
        )
        .then(({ data }) => data);

export const searchPartners = async (name) =>
    axios
        .get(`${BASE_URL}/partners/search`, {
            withCredentials: true,
            params: { query: name },
        })
        .then(({ data }) => data);

export const searchUsers = async ({ fetchCalendarConnectivity, ...query }) =>
    axios
        .post(`${BASE_URL}/users/search`, query, {
            params: { fetchCalendarConnectivity },
            withCredentials: true,
        })
        .then(({ data }) => data);

export const signUp = async ({ invite, name, password, phone }) =>
    axios
        .post(
            `${BASE_URL}/auth/sign-up/${invite}`,
            { name, password, phone },
            { withCredentials: true }
        )
        .then(({ data }) => data);

export const updatePartner = async ({ id, signal, ...rest }) =>
    axios
        .put(`${BASE_URL}/partners/${id}`, rest, {
            signal,
            withCredentials: true,
        })
        .then(({ data }) => data);

export const updateTeamMember = async ({
    id,
    notifications,
    partner,
    permissions,
    role,
}) =>
    axios
        .put(
            `${BASE_URL}/partners/${partner}/members/${id}`,
            { notifications, permissions, role },
            { withCredentials: true }
        )
        .then(({ data }) => data);

export const updateUser = async ({ email, name, phone }) =>
    axios
        .patch(
            `${BASE_URL}/users`,
            { email, name, phone },
            { withCredentials: true }
        )
        .then(({ data }) => data);

export default {
    addPaymentMethod,
    addTeamMember,
    fetchInviteDetails,
    fetchPartner,
    fetchPartners,
    fetchPartnerProducts,
    fetchPartnerStripeStatus,
    fetchPartnerStripeURL,
    fetchPerformanceReport,
    fetchPaymentMethods,
    fetchTeam,
    fetchTeamMember,
    fetchUser,
    joinWaitlist,
    login,
    logout,
    removeTeamMember,
    requestReset,
    searchUsers,
    searchPartners,
    updatePartner,
    updateTeamMember,
    updateUser,
};
